body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
#content
{
    height: 100%;
    width: 100%;
}
body, html {
  height: 100%;
  margin: 0;
}
.home {
  /* The image used */
  background-image: url(/assets/img/headbg1.jpg);
  

  /* Full height */
  height: 100%; 
  width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: aliceblue;
}
.home{
  padding: 100px;
}
.home h1{
  margin: 0px;
}
.home img{
  border-radius: 50%;
  border:10px solid white;
  max-width: 100%;
  height: auto;
}
#menu {
	top:0px;
	z-index:9999;
	display: none;
}
#menu2 {
	position:fixed;
	top:0px;
  z-index:1;
}
.navig{
  position: sticky;
  top: 0;
  z-index: 1;
}
.about{
  padding: 100px;  
}
.proj-head{
  padding: 100px;  
}
.resume{
  padding: 100px;
  background: #2B2B2B;
  color: white;
}
.active{
  background: rgba(0,0,0,0.1);
}
.resume .info {
  font: 20px 'librebaskerville-italic', serif;
  color: #ccc;
  margin-top: 9px;
}
.resume p{
  margin: 0px;
}
.resume h5{
  margin-top: 20px;
}
/* .sidenavig ul{
  margin-top: 10px;
} */
.sidenav-overlay{
  z-index: 0 !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #222; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #111; 
}
.half{
  background: #2B2B2B;
  width: 100%;
  height: 300px;
}
.proj-card{
  margin: -100px 50px 50px 50px !important;
}
.proj-cont{
  padding: 50px;
}

.slide-item img{
  max-height: 400px;
  max-width: 500px;
}
.slide-obj{
  margin: 100px;
}
.contact{
  padding: 100px;
  background: black;
  color: white;
}
.img-modal{
  padding: 0px !important;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.dia-modal{
  padding: 0px !important;
  max-width: 100%;
}
.ytvideo{
  width: 1000px;
  max-width: auto;
  height: 600px;
}
@media screen and (max-width: 768px) {
  .proj-card{
    margin: -150px 20px 20px 20px !important;
  }
  .slide-item img{
    max-height: 400px;
    max-width: 250px; 
  }
  .slide-obj{
    margin-left: 5px;
    margin-right: 5px;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .about{
    padding: 50px;  
  }
  .proj-head{
    padding: 50px;  
  }
  .resume{
    padding: 50px;
    
  }
  .contact{
    padding: 50px;
  }
  h1{
    font-size: 3.5rem !important;
  }
  h2{
    font-size: 3.0rem !important;
  }
  h3{
    font-size: 2.5rem !important;
  }
  h4{
    font-size: 2.0rem !important;
  }
  h5{
    font-size: 1.5rem !important;
  }
  .proj-cont{
    padding: 20px;
  }
  .apptitle{
    display: inline-flex;
    align-items: center;
    margin: 10px;
  }
  .apptitle img{
    max-width: 100px;
  }
  .img-modal{
    padding: 0px !important;
    max-width: none;
    max-height: none;
  }
  .dia-modal{
    padding: 0px !important;
  }
  
  .ytvideo{
    width: auto;
    height: auto;
  }
  
}
.slick-next:before, .slick-prev:before {
  color: black !important;
}
.backbut a{
  align-content: center;
  height: 64px !important;
  line-height: 64px !important;
}
.project-item{
  height: 250px;
 
    overflow: hidden;
    position: relative;
}
.project-item:hover .inactive{
  height: 0px;
  transition: opacity 0.5s ease-out;
  opacity: 0;
}
.project-item .inactive{
  color: #fff !important;
}
.proj-li .row .card{
  background: #2B2B2B;
  color: white;
}
.project-item img{
  max-width: 100%;
  height: auto;
}


.project-item:hover .active{
  position: absolute;
  opacity: 1;
  width: 100%;
  top: 0;
  height: 100%;
  overflow: hidden;
  transition: all 0.4s;
}


.android .active{
  top: 100%;
  height: 0px;
  opacity: 0;
  color: white;
  background: #A4C639;
  padding: 20px;
  margin-left: -24px !important;
}

.python .active{
  top: 100%;
  height: 0px;
  opacity: 0;
  color: white;
  background: #306998;
  padding: 20px;
  margin-left: -24px !important;
}
.web .active{
  top: 100%;
  height: 0px;
  opacity: 0;
  color: white;
  background: #e44d26;
  padding: 20px;
  margin-left: -24px !important;
}
.react .active{
  top: 100%;
  height: 0px;
  opacity: 0;
  color: white;
  background: #61dafb;
  padding: 20px;
  margin-left: -24px !important;
}

.contact li{
  display: inline;
  padding-right: 20px;
}
.contact .fa{
  font-size: 35px !important;
  color: white;
}
.copyright{
  width: 100%;
  margin-bottom: -50px;
  
}
.row{
  overflow-x: hidden !important;
}


.apptitle{
  display: inline-flex;
  align-items: center;

}
.resume{
  background-image:linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ),  url(/assets/img/resume_background1.jpg);
  height: 100%; 
  width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

}
.contact{
  background-image:linear-gradient( rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.9) ),  url(/assets/img/contact.jpg);
  height: 100%; 
  width: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;

}
.MuiDialog-paperWidthSm {
  max-width: 100% !important;
  max-height: 100%;
}
#myImg:hover{
  opacity: 0.6;
    transition: opacity 0.5s ease-in-out;
    -moz-transition: opacity 0.5s ease-in-out;
    -webkit-transition: opacity 0.5s ease-in-out;
    cursor: pointer;
}
